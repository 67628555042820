<template>
  <div class="tag table-page">
    <el-card class="search">
      <el-form ref="searchForm" :model="searchForm" inline>
        <el-form-item label="关键词" prop="name">
          <el-input v-model.trim="searchForm.name" placeholder="关键词" class="input" clearable />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" @click="search">搜索</el-button>
          <el-button size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="content">
      <div class="btn-wrap">
        <el-button size="mini" type="danger" @click="handleBatchDel">批量删除</el-button>
      </div>
      <el-table :data="list" element-loading-text="Loading" border fit highlight-current-row
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="文章标题" align="center">
          <template slot-scope="scope">
            {{ scope.row.article.title }}
          </template>
        </el-table-column>
        <el-table-column label="文章封面" align="center">
          <template slot-scope="scope">
            <Cover :url="scope.row.article.cover" />
          </template>
        </el-table-column>
        <el-table-column label="评论人" align="center">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column label="评论内容" align="center">
          <template slot-scope="scope">
            {{ scope.row.content }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDel(scope.row.id, scope.row.name)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background :page-size.sync="pageInfo.pageSize" :current-page.sync="pageInfo.pageNo"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
  </div>
</template>

<script>
import Cover from "@/components/Cover"
const compLabel = "消息"
const searchForm = { name: "" }

export default {
  name: 'Tag',
  components: {
    Cover
  },
  data() {
    return {
      compLabel,
      list: [],
      choosedList: [],
      searchForm: { ...searchForm },
      total: 0,
      pageInfo: {
        pageNo: 1,
        pageSize: 10
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取消息
    getList() {
      this.$request.post("/comment/list", { ...this.searchForm, ...this.pageInfo }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    search() {
      this.pageInfo.pageNo = 1;
      this.getList();
    },
    reset() {
      this.searchForm = { ...searchForm }
      this.search()
    },
    handleSelectionChange(val) {
      this.choosedList = val;
    },
    handleSizeChange() {
      this.pageInfo.pageNo = 1
      this.getList();
    },
    handleCurrentChange() {
      this.getList()
    },
    handleBatchDel() {
      if (!this.choosedList.length) {
        this.$message.warning(`请先选择要删除的${compLabel}！`)
        return;
      }
      this.$confirm(`是否确认删除这些${compLabel} ？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.post("/tags/del", { ids: this.choosedList.map(t => t.id) }).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.search();
            }
          })
        })
        .catch(() => { })
    },
    handleDel(id, name) {
      this.$confirm(`是否删除${compLabel}：${name} ？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.post("/tags/del", { ids: [id] }).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.search()
            }
          })
        })
        .catch(() => { })
    }
  },
}
</script>
